import { useState } from "react";
import GraphQLMutation from "./database/Mutation.js";
import { prettyFloat } from "../Helper.js";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('fr', fr);

const Cart = (props) => {
  let listItems = [];
  let listCommentsZone = [];
  let reportDeliveryZone = [];
  let today = new Date();

  const [selectedDate, setSelectedDate] = useState(null);
  let suppliers = JSON.parse(localStorage.getItem("suppliers"));

  const getDayName = (dayNumber) => {
    const daysOfWeek = [
      "dimanche",
      "lundi",
      "mardi",
      "mercredi",
      "jeudi",
      "vendredi",
      "samedi",
    ];

    return daysOfWeek[dayNumber - 1];
  };

  console.log(
    "%c Cart init refresh product list because props change (from Cart function)",
    "color: #666"
  );

  let openOrder = (e) => {
    e.target.closest(".line").nextSibling.classList.toggle("open");
  };

  let getTotal = (vendors) => {
    let total = 0;
    for (let i = 0; i < vendors.length; i++) {
      let product = vendors[i];
      total += product.price * product.count;
    }
    return total;
  };

  let buildFormData = function (formData, data, parentKey) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;

      formData.append(parentKey, value);
    }
  };

  let jsonToFormData = function (data) {
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
  };

  let setComment = (e) => {
    if (props.cart.length) {
      document.getElementById("comments-zone").classList.toggle("show");
      return false;
    }
  };

  let setReportDelivery = (e) => {
    if (props.cart.length) {
      document.getElementById("delivery-zone").classList.toggle("show");
      return false;
    }
  };

  let submitCart = (e) => {
    if (props.cart.length) {
      let currentCart = props.cart;
      let restaurantId = props.user.restaurantAssociations[0].restaurant.id;
      let orders = [];
      for (let i = 0; i < currentCart.length; i++) {
        let order = false;
        let ordersFiltered = orders.filter(
          (order) => order.supplier === currentCart[i].vendorid
        );
        if (ordersFiltered.length > 0) {
          order = ordersFiltered[0];
        } else {
          let message = document.getElementById(
            "message_" + currentCart[i].vendorid
          ).value;
          let info = document.getElementById(
            "info_" + currentCart[i].vendorid
          ).value;
          let delivery = selectedDate;
          let deliveryAsDate = new Date(delivery);
          let doSendDelivery = selectedDate ? (today.toDateString() !== deliveryAsDate.toDateString()) : false;
         
          function setZero(nombre) {
            return nombre < 10 ? "0" + nombre : nombre;
          }

          let deliveryDay =
          deliveryAsDate.getFullYear() +
            "-" +
            setZero(deliveryAsDate.getMonth() + 1) +
            "-" +
            setZero(deliveryAsDate.getDate());
           
          let suppliers = JSON.parse(localStorage.getItem("suppliers"));
          let supplierId = currentCart[i].vendorid;
          let supplier = null;
          for (let currentSupplier of suppliers) {
            if (currentSupplier.id == supplierId) {
              supplier = currentSupplier;
              break;
            }
          }
          let hour = "08:30:00";
          if (supplier) {
            let deliveries = supplier.deliveries;
            if (deliveries.length > 0) {
              let delivery = supplier.deliveries[0];
              hour = delivery.hour;
            }
          }

          let deliveryForMutation = deliveryDay + ' ' + hour;

          order = {
            id: currentCart[i].orderid ?? 0,
            supplier: currentCart[i].vendorid,
            products: [],
            info: info,
            message: message,
          };

          if(doSendDelivery){
            order.delivery = deliveryForMutation;
          }
          orders.push(order);
        }
        order.products.push({
          id: currentCart[i].id,
          quantity: currentCart[i].count,
        });
      }

      orders = { orders: orders };
      orders = jsonToFormData(orders);

      let query = new GraphQLMutation({
        mutationType: "SubmitRestaurantCart",
        variables: {
          restaurant: { type: "Int!", value: restaurantId },
          confirm: { type: "Boolean", value: true },
        },
        data: orders,
        fields: [
          "success",
          "message",
          "orders { id }",
          "errors { key, value }",
        ],
        success: function (data) {
          if (data.success) {
            console.warn("Api save cart order: ok", data);
            window.location.replace("/validate");
          } else {
            console.error("Api save cart order: error", data);
          }
        },
        error: function (message, data) {
          console.error(message, data);
          alert(message);
        },
        after: function () {},
      });
      query.execute();
    }
  };

  if (props.cart.length) {
    let currentCart = props.cart;

    const listVendors = currentCart.reduce((groups, item) => {
      const group = groups[item.vendor] || [];
      group.push(item);
      groups[item.vendor] = group;
      return groups;
    }, {});

    
    listItems = Object.keys(listVendors).map((k, index) => (
      <div className="d-flex order" key={index}>
        <div className="line">
          <div>{k}</div>
          <div>{listVendors[k].length}</div>
          <div>
            {prettyFloat(getTotal(listVendors[k]))} €
            {getTotal(listVendors[k]) < listVendors[k][0].vendordelivery ? (
              <i
                class="warning-penalty"
                title={
                  prettyFloat(listVendors[k][0].vendorpenalty) +
                  " € de frais de livraison si vous n'atteignez pas " +
                  prettyFloat(listVendors[k][0].vendordelivery) +
                  " € de commande"
                }
              ></i>
            ) : (
              ""
            )}
          </div>
          <div className="opener" onClick={openOrder}>
            <img alt="open" src="./img/arrowDown.png" width={20} />
          </div>
        </div>
        <div class="delivery-cart">
        {suppliers.map((supplier) => (
          <div key={supplier.id}>
            {supplier.name === k && (
              <div>
                {supplier.deliveries.map((delivery, indexDelivery) => (
                      <p key={indexDelivery}>Livré le {getDayName(delivery.day)}</p>
                    ))}
              </div>
            )}
          </div>
          ))}
        </div>
        <div className="products">
          {getTotal(listVendors[k]) < listVendors[k][0].vendordelivery ? (
            <div className="d-flex product align-items-center alert-penalty">
              <div>
                {prettyFloat(listVendors[k][0].vendorpenalty)} € de frais de
                livraison si vous n'atteignez pas{" "}
                {prettyFloat(listVendors[k][0].vendordelivery)} € de commande
              </div>
            </div>
          ) : (
            ""
          )}
          {Object.keys(listVendors[k]).map((i, index) => (
            <div
              className="d-flex product align-items-center"
              key={index}
              data-id={listVendors[k][i].id}
              data-count={listVendors[k][i].count}
            >
              <div>{listVendors[k][i].name}</div>
              <div className="text-center">
                <button
                  className="removefromcart"
                  onClick={props.updateProductCart}
                  data-price={listVendors[k][i].price}
                  data-vendorid={listVendors[k][i].vendorid}
                  data-vendordelivery={listVendors[k][i].vendordelivery}
                  data-vendorpenalty={listVendors[k][i].vendorpenalty}
                  data-vendor={k}
                  data-name={listVendors[k][i].name}
                  data-id={listVendors[k][i].id}
                  data-count={listVendors[k][i].count}
                >
                  <img alt="minus" src="./img/minus.svg" width={15} />
                </button>
              </div>
              <input
                type="text"
                className="freeInput"
                value={listVendors[k][i].count || 0}
                data-price={listVendors[k][i].price}
                data-vendorid={listVendors[k][i].vendorid}
                data-vendordelivery={listVendors[k][i].vendordelivery}
                data-vendorpenalty={listVendors[k][i].vendorpenalty}
                data-vendor={k}
                data-name={listVendors[k][i].name}
                data-id={listVendors[k][i].id}
                data-count={listVendors[k][i].count}
                onChange={props.updateProductCart}
              />
              <div className="text-center">
                <button
                  className="addtocart"
                  onClick={props.updateProductCart}
                  data-price={listVendors[k][i].price}
                  data-vendorid={listVendors[k][i].vendorid}
                  data-vendordelivery={listVendors[k][i].vendordelivery}
                  data-vendorpenalty={listVendors[k][i].vendorpenalty}
                  data-vendor={k}
                  data-name={listVendors[k][i].name}
                  data-id={listVendors[k][i].id}
                  data-count={listVendors[k][i].count}
                >
                  <img alt="plus" src="./img/plus.svg" width={15} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    ));

    listCommentsZone = Object.keys(listVendors).map((k, index) => (
      <div className="d-flex comments" key={index}>
        <b>{k}</b>
        <label>Informations complémentaires</label>
        <textarea id={"info_" + listVendors[k][0].vendorid + ""}></textarea>
        <label>Message à destination de Restofuté</label>
        <textarea id={"message_" + listVendors[k][0].vendorid + ""}></textarea>
      </div>
    ));

   // Report delivery
const isWeekDay = (date) => {
  const day = date.getDay();
  return day === 0 || day === 6;
};

const sameDay = (first, second) => {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
};

const filterDate = (date, supplierId) => {
  const today = new Date();
  if (date < today) {
    return false;
  }

  if (isWeekDay(date)) {
    return false;
  }

  if (sameDay(today, date)) {
    const currentHour = today.getHours() * 100 + today.getMinutes();

    const suppliers = JSON.parse(localStorage.getItem("suppliers"));
    const supplier = suppliers.find((currentSupplier) => currentSupplier.id === supplierId);

    if (supplier) {
      const deliveries = supplier.deliveries;

      if (deliveries.length > 0) {
        const delivery = deliveries[0];
        const supplierMaxHourAsInt = parseInt(delivery.maxHour.replace(":", ""));

        if (currentHour >= supplierMaxHourAsInt) {
          return false;
        }
      }
    }
  }
  return true;
};


    reportDeliveryZone = Object.keys(listVendors).map((k, index) => (
      <div className="d-flex delivery" key={index}>
        <b>{k}</b>
        <label htmlFor="datePicker">Sélectionnez une date:</label>
        <DatePicker
          filterDate={(date) => filterDate(date, listVendors[k][0].vendorid)}
          //excludeDates={getExcludedDate(listVendors[k][0].vendorid)}
          id={"delivery_" + listVendors[k][0].vendorid + ""}
          onChange={(date) => setSelectedDate(date)}
          local="fr"
          selected={selectedDate}
          locale="fr"
          dateFormat="dd/MM/yyyy"
          icon="fa fa-calendar"
        />
      </div>
    ));
  }

  return (
    <div className="cart" id="cart">
      <button className="close-cart" onClick={props.toggleCart}>
        <img src="./img/close.svg" alt="close" />
      </button>

      <h2>Mon panier</h2>
      {listItems?.length > 0 ? (
        <div className="header-cart d-flex gap-4">
          <span>Commande</span>
          <span>Qté</span>
          <span>Total</span>
        </div>
      ) : (
        ""
      )}
      {listItems?.length > 0 ? listItems : <p>Votre panier est vide</p>}
      {listCommentsZone?.length > 0 ? (
        <a href="#" class="comments-link mt-5" onClick={setComment}>
          Ajouter des commentaires avant finalisation
        </a>
      ) : (
        ""
      )}
      {listCommentsZone?.length > 0 ? (
        <div id="comments-zone">{listCommentsZone}</div>
      ) : (
        ""
      )}
      {reportDeliveryZone?.length > 0 ? (
        <a href="#" className="delivery-link mt-8 " onClick={setReportDelivery}>
          Sélectionnez une date de report de livraison
        </a>
      ) : (
        ""
      )}
      {reportDeliveryZone?.length > 0 ? (
        <div id="delivery-zone">{reportDeliveryZone}</div>
      ) : (
        ""
      )}
      {listItems?.length > 0 ? (
        <button className="btn-primary mt-3" onClick={submitCart}>
          Finaliser ma commande
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default Cart;